export class NewsList {
    constructor($wrapper) {
        this.$wrapper = $wrapper;
        this.$list = this.$wrapper.find('.js-news-list');
        this.$more = this.$wrapper.find('.js-news-list-more');
        this.currentPage = 1;
        this.ajaxCall = false;

        this.params = [];
        this.params.ajaxUrl = window.ajaxObject.ajaxURL || false;
        this.params.ajaxAction = 'moreNews';
        this.params.ajaxMethod = 'news';

        this.init();
    }

    init() {
        var self = this;
        self.$more.on('click', (e) => {
            e.preventDefault();
            if (!self.ajaxCall) {
                self.more();
            }
        });
    }

    more() {
        var self = this;

        self.$list.addClass('loading');
        self.ajaxCall = true;
        self.currentPage++;
        $.ajax({
            url: self.params.ajaxUrl,
            method: 'POST',
            data: {
                action: self.params.ajaxAction,
                method: self.params.ajaxMethod,
                page: self.currentPage,
            },
            success: function(data) {
                console.log(data);
                self.$list.append(data.data.html);
                if (data.data.newPage == null) {
                    self.$more.remove();
                }
                else {
                    self.$more.data('page', data.data.newPage);
                }
                self.ajaxCall = false;
                self.$module.removeClass('loading');
            },
        }).fail(function(textStatus, errorThrown) {
            self.$module.removeClass('loading');
            self.ajaxCall = false;
            console.log(textStatus);
            console.log(errorThrown);
        });
    }
}
