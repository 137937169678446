/* eslint-disable */
import {NewsList} from '../src/news-list';
import Cookieconsent from 'vanilla-cookieconsent'

export default {
    init() {

      // ========================================
      // Cookie
      // ========================================
        let cc = initCookieConsent();

      // run plugin with your configuration
        cc.run({
            current_lang: 'fr',

            gui_options: {
                consent_modal: {
                    layout: 'box',              // box/cloud/bar
                    position: 'bottom left',    // bottom/middle/top + left/right/center
                    transition: 'slide',        // zoom/slide
                },
                settings_modal: {
                    layout: 'box',              // box/bar
                  // position : 'left',       // left/right
                    transition: 'slide',        // zoom/slide
                },
            },
          // si les cookies sont acceptés (ne pas confondre avec le fait de cliquer sur accepter)
            onAccept: function (cookie) {
              // document.querySelector('.cookies-button').classList.add('is-visible');
            },
            onFirstAction: function (cookie) {
                window.location.reload();
            },
            onChange: function (cookie) {
                window.location.reload();
            },

            languages: {
                'fr': {
                    consent_modal: {
                        title: 'Hello ! voici des <span class="large">Cookies</span>',
                        description: 'Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ce dernier ne sera fixé qu\'après accord. <button type="button" data-cc="c-settings" class="cc-link">Choisir mes préférences</button>',
                        primary_btn: {
                            text: 'Tout accepter',
                            role: 'accept_all',             // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Tout rejeter',
                            role: 'accept_necessary',        // 'settings' or 'accept_necessary'
                        },
                    },
                    settings_modal: {
                        title: 'Gestion de préférence des cookies',
                        save_settings_btn: 'Enregistrer',
                        accept_all_btn: 'Tout accepter',
                        reject_all_btn: 'Tout rejeter',
                        close_btn_label: 'Fermer',
                        cookie_table_headers: [
                        {col1: 'Nom'},
                        {col2: 'Domaine'},
                        {col3: 'Expiration'},
                        {col4: 'Description'},
                        ],
                        blocks: [
                        {
                        // title: 'Cookie usage 📢',
                            description: 'Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser.',
                        }, {
                            title: 'Cookies nécessaires au bon fonctionnement du site',
                            description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true,
                            },
                        }, {
                            title: 'Publicitaires (ou presque)',
                            description: 'Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.',
                            toggle: {
                                value: 'advertising',
                                enabled: false,
                                readonly: false,
                            },
                        }, {
                            title: 'Google Analytics',
                            description: 'Ces cookies permettent d\'analyser les statistiques de consultation de notre site.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false,
                            },
                    // cookie_table: [             // list of all expected cookies
                    //     {
                    //         col1: '^_ga',       // match all cookies starting with "_ga"
                    //         col2: 'google.com',
                    //         col3: '2 years',
                    //         col4: 'description ...',
                    //         is_regex: true
                    //     },
                    //     {
                    //         col1: '_gid',
                    //         col2: 'google.com',
                    //         col3: '1 day',
                    //         col4: 'description ...',
                    //     }
                    // ]
                    // }, {
                    //     title: 'Advertisement and Targeting cookies',
                    //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                    //     toggle: {
                    //         value: 'targeting',
                    //         enabled: false,
                    //         readonly: false
                    //     }
                    // }, {
                    //     title: 'More information',
                    //     description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                        },
                        ],
                    },
                },
            },
        });

    mapboxgl.accessToken = 'pk.eyJ1IjoicGxhY2VkdXZpbGxhZ2UiLCJhIjoiY2tjbjUzeWpvMDdxYjJwcnF3dHp3aTV5aiJ9.ODrPRLcPiS-f-UC36JGPeQ';

    $('.js_load-map').each(function () {
        let elt = this;
        let markers = $(elt).attr('data-markers');
        const map = new mapboxgl.Map({
            container: $(elt).attr('id'),
            style: 'mapbox://styles/mapbox/streets-v11',
            // center: [$(elt).attr('data-lng'), $(elt).attr('data-lat')],
            center: [4.3446771, 43.9075003],
            zoom: 12,
        });
        // map.scrollZoom.disable();

        var coor = jQuery.parseJSON(markers);
        var bounds = new mapboxgl.LngLatBounds();

        coor.forEach(function (key) {
          const el = document.createElement('div');
          const containerLogo = document.createElement('div')
          containerLogo.setAttribute('class', 'container-pin');

          const logo = document.createElement("IMG");
          logo.setAttribute('class', 'img-pin');
          logo.src=$(elt).attr('data-pin');

          el.className = 'marker';
          // el.setAttribute('style', "content: url('" + $(elt).attr('data-pin') + "')");
          containerLogo.append(logo);
          el.append(containerLogo);
          // new mapboxgl.Marker({color: $(elt).attr('data-color')})
          new mapboxgl.Marker(el)
            .setLngLat([key[0], key[1]])
            .addTo(map);

          bounds.extend(key);
        });

      // permet d'ajuster le zoom en fonction des markers
        map.fitBounds(bounds, {padding: 100});
        var mapLat = map.getBounds().getCenter().lat;
        var mapLong = map.getBounds().getCenter().long;
        map.setCenter = (mapLat, mapLong);
        var zoomAjust = coor.length > 1 ? 2 : 1;
        var mapZoom = map.getZoom()
        var newZoom = mapZoom - zoomAjust;
        map.setZoom(newZoom);
    });


    // News list
    $('.js-news-list-wrapper').each(function () {
        new NewsList($(this));
    })

    const discoverButton = document.querySelector('.discover-button');
    if (discoverButton) {
        discoverButton.addEventListener('click', function () {
            const top = document.querySelector('#introduction').getBoundingClientRect().top;
            window.scroll({
                top,
                behavior: 'smooth'
            });
        });
    }

    const returnToTopButton = document.querySelector('.return-to-top-button');
    if (returnToTopButton) {
        returnToTopButton.addEventListener('click', function () {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        });
    }

    if ($('.home-swiper .swiper-slide').length > 2) {
        var swiperHistoiresSlider = new Swiper('.home-swiper', {
          // Optional parameters
            direction: 'horizontal',
            loop: true,
            allowTouchMove: false,
            navigation: {
                nextEl: '.home-swiper .swiper-button-next',
                prevEl: '.home-swiper .swiper-button-prev',
            },
        });
    }


    if ($('.home-swiper-alone .swiper-slide-alone').length > 2) {
        var swiperHistoiresSlider = new Swiper('.home-swiper-alone', {
          // Optional parameters
            direction: 'horizontal',
            loop: false,
            allowTouchMove: false,
        });
    }

    let duration = 500;
    let distance = '60px';
    ScrollReveal().reveal('.reveal-top', {origin: 'top', distance: distance, duration: duration, easing: 'ease'});
    ScrollReveal().reveal('.reveal-right', {origin: 'right', distance: distance, duration: duration, easing: 'ease'});
    ScrollReveal().reveal('.reveal-bottom', {origin: 'bottom', distance: distance, duration: duration, easing: 'ease'});
    ScrollReveal().reveal('.reveal-left', {origin: 'left', distance: distance, duration: duration, easing: 'ease'});
    ScrollReveal().reveal('.reveal-top-sequenced', {
        origin: 'top',
        distance: distance,
        duration: duration,
        easing: 'ease',
        interval: 50
      });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    var swiperActualites = new Swiper(".actualites-swiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            991: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        navigation: {
            nextEl: ".actualites-slider .swiper-button-next",
            prevEl: ".actualites-slider .swiper-button-prev",
        },
      });

    var swiperActivites = new Swiper(".activites-swiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        navigation: {
            nextEl: ".activites-slider-arrows .swiper-button-next",
            prevEl: ".activites-slider-arrows .swiper-button-prev",
        },
      });
    },
    finalize() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
